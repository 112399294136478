.project-card {
  width: 20rem;
  height: 30rem;
  border-radius: 1rem;
  background: #151616;
  position: relative;

  overflow: hidden;
  cursor: pointer;

  margin: auto;
  margin-bottom: 2rem;
  user-select: none;
}
.project-card:hover .overlay:hover {
  /* box-shadow: inset 0px -363px 292px -300px #2e2f3180; */
  /* opacity: 1; */
  /* background: none !important; */
}

.project-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  z-index: -1;
  border-radius: 0px 0px 2rem 2rem;
}

.project-card .overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  border-radius: 1rem;
}

.project-card .info {
  padding: 0 1.3rem 2rem 1.3rem;
  z-index: 3;
  position: absolute;
  bottom: 0;
}
.info h3 {
  grid-area: txt;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 1.4;
  margin-bottom: 0rem;
  margin-top: 0;
}
.info .tags {
  display: flex;
  gap: 0.6rem;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--txt-med);
}
.info .tags.links a {
  color: white;
  text-decoration: none;
  margin-top: 0.6rem;
}

.project-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: var(--color-white);
  text-decoration: none;
  padding: 0 1.3rem 2rem 1.3rem;
  z-index: 3;
  position: absolute;
  top: 0;
}

.links {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  color: gray !important;
  cursor: default;
}

.disabled-link {
  color: gray !important;
  cursor: default;
}

.projects-disclaimer {
  font-size: 0.8rem;
  color: var(--color-gray-dark);
  margin-top: 0.5rem;
}

@media screen and (max-width: 680px) {
  .cards {
    display: block !important;
  }
  .card {
    width: 100% !important;
    height: 33rem !important;
  }
  #intro .txt a {
    display: hidden;
  }
  .hidden #intro .txt a {
    display: block;
  }
  .cont {
    grid-template-areas:
      'datum'
      'extra'
      'titel'
      'ort'
      'txt' !important;
    grid-template-columns: 100% !important;
  }
  .txt h1 {
    font-size: 7vw !important;
  }
}
