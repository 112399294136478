.hero-main {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .txt h1 {
  font-size: 4rem;
  margin: 0.2rem 0;
} */
