/* import colors */
@import './colors.css';

/* size variables */
@import './variables.css';

/* Other components */
@import './navbar.css';
@import './mainview.css';
@import './navigation.css';
@import './contact.css';
@import './projectcard.css';
@import './gradient.css';
@import './hero.css';

.App {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* background-color: transparent; */
  background-color: var(--color-primary);

  /* min-height: 100vh; */
  /* width: 100%; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-gray);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.gradient-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}
.splash {
  width: 100%;
  position: fixed;
  top: 0;
  background: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: initial;
}

/* For SVG icons */
.icon-white {
  filter: invert(100%);
  margin-right: 0.5rem;
  align-self: center;
  width: 1.5rem;
}
