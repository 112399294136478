.contact {
  display: flex;
  flex-flow: column;
  gap: 0.7rem;
  width: 100%;
  transition: opacity 0.3s;
}

.contact a {
  text-decoration: none;
  color: var(--txt-med);
  padding: 0rem 1.5rem;
}

.contact a:hover {
  color: white;
}
