header {
  display: grid;
  grid-template: 'main ';
  grid-template-columns: minmax(100px, 1080px);
  place-content: center;
  position: fixed;
  width: 100%;
  /* background-color: var(--bg-root); */
  height: 60px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  top: 0;
}

.mid {
  place-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-template: 'logo search links' / 1fr auto 1fr;
}

.logo-holder {
  justify-self: flex-start;
  display: grid;
  grid-auto-flow: column;
}
.charBody {
  height: 60px;
  width: 31px;
  background-color: var(--color-quinary);
  border: 0;
  position: relative;
}
.chareyes {
  position: relative;
  background-color: black;
  height: 15px;
  width: 15px;
  left: 15px;
  top: 5px;
}
.eye {
  position: absolute;
  background-color: white;
  height: 5px;
  width: 5px;
  left: 10px;
  top: 8px;
  animation: eyeMove 2s infinite, blink 1.5s;
}

@media screen and (max-width: 1650px) {
  header {
    padding: 0rem 1rem;
    box-sizing: border-box;
  }
}

/* Animation for the eye class */
@keyframes eyeMove {
  0% {
    left: 10px;
  }
  25% {
    left: 6px;
  }
  50% {
    left: 2px;
  }
  75% {
    left: 6px;
  }
  100% {
    left: 10px;
  }
}
@keyframes blink {
  0% {
    height: 5px;
    top: 8px;
  }

  100% {
    height: 2px;
    top: 10px;
  }
}
