.nav-holder {
  display: flex;
  flex-flow: column;
  align-items: self-end;
  gap: 1rem;
  font-size: 1.5rem;
}

.nav-holder a {
  transition: min-width 0.3s, border 0.3s, padding 0.3s, box-shadow 0.3s,
    background 0.15s;
  width: fit-content;
  min-width: 210px;
  max-width: -webkit-fill-available;
}

.listItem:nth-child(1).first {
  background: var(--color-accent-1);
}
.listItem:nth-child(2).first {
  background: var(--color-accent-2);
}
.listItem:nth-child(3).first {
  background: var(--color-accent-3);
}
.listItem:nth-child(4).first {
  background: var(--color-accent-4);
}
.listItem:nth-child(5).first {
  background: var(--color-accent-5);
}
.listItem:nth-child(6).first {
  background: var(--color-accent-6);
}
.listItem:hover {
  background-color: hsla(0, 0%, 80%, 0.15);
}

.listItem {
  text-decoration: none;
  display: block;
  padding: 0.75rem 1.8rem;
  margin-right: 1rem;
  color: white;
  font-size: 0.95em;
  cursor: pointer;
  transition: border 0.3s, padding 0.3s, box-shadow 0.3s, background 0.15s;
  border-left: 2px solid transparent;
  font-weight: 500;
  border-radius: 0.4rem;
}

.mainitem {
  padding: 0.5rem 0.5rem;
}

.current {
  /* empty */
}

.first {
  color: black;
  color: black !important;
  padding-left: 2rem;
  font-weight: 600;
  transform-origin: left;
  animation: pop 0.25s ease-out;
}

.hidden .a {
  transition: min-width 0.3s;
  min-width: 0px;
  width: fit-content;
}

.title {
  font-size: 12px !important;
  font-weight: 700 !important;
  margin: 0.2em 0em 1rem 0rem;
  text-transform: capitalize;
  color: #b2b2b2;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  width: 100%;
  text-align: center;
}

.hidden .title {
  opacity: 0;
}

.hidden .listItem {
  border: 0;
  color: black;
}

.hidden .listItem:hover {
  background: #fbfbfb;
}

.hidden .contact,
.hidden section {
  opacity: 0;
}

.hidden .main,
.hidden .holder,
.hidden .sider {
  background: transparent !important;
}

.hidden {
  background: transparent !important;
  box-shadow: unset !important;
}
.hidden .nav-holder a {
  transition: min-width 0.3s;
  min-width: 0px;
  width: fit-content;
}

.hidden .nav-holder .title {
  opacity: 0;
}

.hidden .nav-holder .listItem {
  border: 0;
  color: black;
}
.hidden .nav-holder .listItem:nth-child(1) {
  background: var(--color-accent-1);
}
.hidden .nav-holder .listItem:nth-child(2) {
  background: var(--color-accent-2);
}
.hidden .nav-holder .listItem:nth-child(3) {
  background: var(--color-accent-3);
}
.hidden .nav-holder .listItem:nth-child(4) {
  background: var(--color-accent-4);
}
.hidden .nav-holder .listItem:nth-child(5) {
  background: var(--color-accent-5);
}
.hidden .nav-holder .listItem:nth-child(6) {
  background: var(--color-accent-6);
}
.hidden .nav-holder .listItem:hover {
  background: #fbfbfb;
}
