/* Size variables */
:root {
  --size-small: 0.75rem;
  --size-medium: 1rem;
  --size-large: 1.5rem;
  --size-xlarge: 2rem;
  --size-xxlarge: 3rem;
  --size-xxxlarge: 4rem;
  --max-width: 70rem;
}
