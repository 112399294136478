.main-view {
  display: grid;
  grid-template: 'sidebar main';
  grid-template-columns: 1fr minmax(600px, 770px);
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
}
.main-view section {
  transition: opacity 0.3s;
}

.main-view,
.main-view .main .holder,
.main-view .sider {
  transition: background 0.3s;
}

.main {
  padding: 1.5rem;
}
.holder {
  scrollbar-color: #bebebe transparent;
  scrollbar-width: thin;
}
.holder:hover {
  scrollbar-color: #bebebe #f2f2f2;
  scrollbar-width: revert !important;
}
.holder::-webkit-scrollbar {
  width: 15px;
  padding: 10px;
}
.holder::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* background-color: #bebebe; */
  border: 5px solid transparent;
  background-clip: content-box;
}
.sider:hover .holder::-webkit-scrollbar {
  width: 15px;
}
.sider:hover .holder::-webkit-scrollbar-track {
  border-radius: 10px;
}
.sider:hover .holder::-webkit-scrollbar-thumb {
  border: 0;
  border-radius: 10px;
}
.sider:hover .holder::-webkit-scrollbar-thumb:hover {
  background: #818b99;
}
.sider {
  height: 100%;
  width: 310px;
}
.holder {
  padding: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  height: calc(100vh - 90px);
  overflow-y: auto;
}
.sidebar {
  justify-self: right;
}

.card-col:nth-child(1) {
  margin-top: 5rem;
}
.cards {
  display: flex;
  gap: 1.7rem;
}
.card-col {
  display: flex;
  flex-flow: column;
  gap: 1.7rem;
}
.sidebar,
.sideoptions {
  transition: width 0.3s;
}

/* Sections */
section {
  padding: 6rem 0rem;
}
section:empty {
  display: none;
}
section span {
  display: block;
  max-width: 550px;
}
#intro {
  opacity: 100;
  padding: 0;
  /* margin-bottom: 6rem; */
}
#intro .txt a {
  display: none;
  text-decoration: none;
  color: white;
}

#about a {
  color: var(--color-accent-1);
  text-decoration: none;
}
#about span {
  text-align: justify;
}

#contact a,
#curriculum a {
  color: white;
}
#contact a:hover,
#curriculum a:hover {
  color: hsl(0deg 0% 63%);
}

.cv,
.experience {
  cursor: default;
}

.cv p {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.cv,
.experience h3 {
  font-size: 0.9rem;
}

.cont {
  display: grid;
  grid-template-areas:
    'datum titel'
    'extra ort'
    '. txt';
  grid-template-columns: 11rem 370px;
  gap: 0rem 1rem;
  margin-bottom: 1.3rem;
}
.cont span:nth-child(1) {
  grid-area: datum;
  font-weight: 600;
  font-size: 0.9rem;
  color: hsl(0deg 0% 63%);
  letter-spacing: -0.02rem;
}
.cont span:nth-child(2) {
  grid-area: extra;
  font-size: 0.87rem;
  color: hsl(0deg 0% 63%);
  letter-spacing: -0.02rem;
}
.cont span:nth-child(3) {
  grid-area: titel;
}
.cont span:nth-child(4) {
  grid-area: ort;
  font-size: 0.9rem;
  color: hsl(0deg 0% 63%);
}
.cont span:nth-child(5) {
  grid-area: txt;
  font-size: 0.9rem;
  color: hsl(0deg 0% 63%);
}

.contact {
  display: flex;
  flex-flow: column;
  gap: 0.7rem;
  width: 100%;
  transition: opacity 0.3s;
}
.contact a {
  text-decoration: none;
  color: var(--txt-med);
  padding: 0rem 1.5rem;
}

.contact a:hover {
  color: white;
}

/* Media Queries */
@media screen and (max-width: 680px) {
  .cards {
    display: block !important;
  }
  .card {
    width: 100% !important;
    height: 33rem !important;
  }
  #intro .txt a {
    display: hidden;
  }
  .hidden #intro .txt a {
    display: block;
  }
  .cont {
    grid-template-areas:
      'datum'
      'extra'
      'titel'
      'ort'
      'txt' !important;
    grid-template-columns: 100% !important;
  }
  .txt h1 {
    font-size: 7vw !important;
  }
}

@media screen and (max-width: 1023px) {
  .main-view {
    grid-template: 'main main main' !important;
    grid-template-columns: 1fr !important;
    width: auto !important;
  }
  .sidebar {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  .main-view {
    grid-template: 'sidebar main main';
  }
  .sideoptions {
    display: none;
  }
}

div > .main {
  max-width: 1000px;
  margin: auto;
  padding: 2rem;
  box-sizing: border-box;
  line-height: 1.6;
  color: var(--txt);
  padding: 1.5rem 1.5rem 80vh 1.5rem;
  width: 100%;
  z-index: 100;
}
.txt h1 {
  font-size: 3rem;
  margin: 0.2rem 0;
  line-height: 1.3;
  max-width: 600px;
}

span.movingLink {
  overflow: hidden !important;
  position: relative !important;
  display: inline-block !important;
  text-decoration: none !important;
  color: #527c92 !important;
  font-weight: 700 !important;
  vertical-align: top !important;
}

span.movingLink::before,
span.movingLink::after {
  content: '';
  position: absolute;
  width: 80%;
  left: 10%;
}
span.movingLink::before {
  background-color: #c2aa20 !important;
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}
span.movingLink::after {
  content: attr(data-replace);
  /* height: 100%; */
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  color: #e23f29;
}

span.movingLink:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}
span.movingLink:hover::after {
  transform: translate3d(0, 0, 0);
}

span.movingLink span {
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

span.movingLink:hover span {
  transform: translate3d(-200%, 0, 0);
}
