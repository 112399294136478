/* Define project color variables */
:root {
  --color-primary: #1f2125;
  --color-secondary: #ffc107;
  --color-tertiary: #2c3e50;
  --color-quaternary: #3498db;
  --color-quinary: #c0392b;

  --color-white: #fff;
  --color-black: #000;
  --color-gray: #ccc;
  --color-gray-light: #eee;
  --color-gray-lighter: #f4f4f4;
  --color-gray-lightest: #f5f5f5;
  --color-gray-dark: #777;
  --color-gray-darker: #555;
  --color-gray-darkest: #333;

  --color-accent-1: rgba(130, 245, 250, 0.8);
  --color-accent-2: rgba(130, 235, 250, 0.8);
  --color-accent-3: rgba(130, 215, 250, 0.8);
  --color-accent-4: rgba(130, 175, 250, 0.8);
  --color-accent-5: rgba(130, 145, 250, 0.8);
  --color-accent-6: rgba(130, 125, 250, 0.8);
}
